


































import {
	Component,
	Vue,
	Watch
} from "vue-property-decorator";
import vFreeApply from '@/components/v-free-apply.vue';
import defaultItem from "@/utils/defaultItem";

@Component({
	components: {
		vFreeApply
	}
})
export default class extends Vue {
	private infomations = defaultItem.infomationList();
	private dynamicList = defaultItem.dynamicList();
	private preIndex = 0;
	private nextIndex = 0;
	private detail: any = null;
	private currentList: any = [];
	@Watch("$route")
	routechange(to: any, from: any) {
		const id = Number(this.$route.params.id);
		let exit_index = this.infomations.findIndex((item) => item.id === id);
		let length = this.infomations.length;
		if (exit_index > -1) {
			this.preIndex = exit_index - 1;
			this.nextIndex = exit_index + 1;
			if (this.preIndex === -1) this.preIndex = length - 1;
			if (this.preIndex === length) this.preIndex = length - 1;
			if (this.nextIndex === -1) this.nextIndex = length - 1;
			if (this.nextIndex === length) this.nextIndex = length - 1;
			this.currentList = this.infomations;
			this.detail = {
				...this.infomations[exit_index],
				src: location.href,
			};
			return;
		}
		exit_index = this.dynamicList.findIndex((item) => item.id === id);
		length = this.dynamicList.length;
		if (exit_index > -1) {
			this.preIndex = exit_index - 1;
			this.nextIndex = exit_index + 1;
			if (this.preIndex === -1) this.preIndex = length - 1;
			if (this.preIndex === length) this.preIndex = length - 1;
			if (this.nextIndex === -1) this.nextIndex = length - 1;
			if (this.nextIndex === length) this.nextIndex = length - 1;
			this.currentList = this.dynamicList;
			this.detail = {
				...this.dynamicList[exit_index],
				src: location.href,
			};
			return;
		}
	}
	created() {
		const id = Number(this.$route.params.id);
		let exit_index = this.infomations.findIndex((item) => item.id === id);
		let length = this.infomations.length;
		if (exit_index > -1) {
			this.preIndex = exit_index - 1;
			this.nextIndex = exit_index + 1;
			if (this.preIndex === -1) this.preIndex = length - 1;
			if (this.preIndex === length) this.preIndex = length - 1;
			if (this.nextIndex === -1) this.nextIndex = length - 1;
			if (this.nextIndex === length) this.nextIndex = length - 1;
			this.currentList = this.infomations;
			this.detail = {
				...this.infomations[exit_index],
				src: location.href,
			};
			return;
		}
		exit_index = this.dynamicList.findIndex((item) => item.id === id);
		length = this.dynamicList.length;
		if (exit_index > -1) {
			this.preIndex = exit_index - 1;
			this.nextIndex = exit_index + 1;
			if (this.preIndex === -1) this.preIndex = length - 1;
			if (this.preIndex === length) this.preIndex = length - 1;
			if (this.nextIndex === -1) this.nextIndex = length - 1;
			if (this.nextIndex === length) this.nextIndex = length - 1;
			this.currentList = this.dynamicList;
			this.detail = {
				...this.dynamicList[exit_index],
				src: location.href,
			};
			return;
		}
	}
	navigetor(item: any) {
		const url = this.$router.resolve({
			path: `/dynamicDetails/${item.id}`,
		});
		this.$router.push({
			path: url.href + location.search,
			query: {}
		});
		// window.open(url.href+location.search, '_blank')
	}

}
